<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-response-warning v-if="state.hasError" :error="state.accessError" />

    <div v-if="!state.hasError" class="flex flex-col sm:flex-row sm:justify-between sm:items-center border-b pb-2">
      <div class="flex justify-between items-center">
        <h2 class="font-bold text-xl sm:text-2xl">
          Announcements
        </h2>
        <add-link
          :to="{ name: 'SettingsAnnouncementsCreate' }"
          title="Add"
          class="sm:hidden"
        />
      </div>

      <div class="sm:flex sm:flex-row sm:items-center sm:justify-between sm:divide-x sm:space-x-4 mt-3 sm:mt-0">
        <div class="md:justify-end md:flex md:items-center">

          <div class="relative flex items-start">
            <div class="flex h-5 items-center">
              <input v-model="state.activeOnly" :value="true" id="announcement" aria-describedby="announcement-description" name="announcement" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blumine focus:ring-blumine">
            </div>
            <div class="ml-3 text-sm">
              <label for="announcement" class="font-medium text-big-stone">Active only</label>
            </div>
          </div>
          <text-input type="search" v-model="state.search" placeholder="Search..." class="w-full sm:w-64 lg:w-96 mt-3 md:mt-0 md:ml-4" />
        </div>
        <add-link
          :to="{ name: 'SettingsAnnouncementsCreate' }"
          title="Add"
          class="hidden sm:flex"
        />
      </div>
    </div>

    <div class="bg-concrete rounded-md shadow-sm mt-6 xl:mt-8">
      <div v-if="!state.hasError" class="flex flex-col">
        <v-table :is-loading="state.isLoading">
          <template #header>
            <v-table-header class="leading-tight">Message Type</v-table-header>
            <v-table-header>Message</v-table-header>
            <v-table-header>Display Since</v-table-header>
            <v-table-header>Display Till</v-table-header>
            <v-table-header><span class="sr-only">Actions</span></v-table-header>
          </template>

          <v-table-row v-for="(item, index) in filteredItems" :key="index" class=" hover:bg-gray-50">
            <v-table-col class="font-semibold" v-html="highlightInString(item.message_type, state.search)" />
            <v-table-col class="!whitespace-normal" v-html="highlightInString(item.message, state.search)" />
            <v-table-col class="">{{ formatTime(item.display_since) }}</v-table-col>
            <v-table-col class="">{{ formatTime(item.display_till) }}</v-table-col>
            <v-table-col>
              <div class="text-right space-x-2 flex justify-end items-center">
                <a v-if="item.link" :href="item.link" target="_blank" title="Link" class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none">
                  <span class="sr-only">open_in_browser</span>
                  <span class="material-icons-outlined">open_in_browser</span>
                </a>
                <router-link
                  :to="{ name: 'SettingsAnnouncementsEdit', params: { id: item.id } }"
                  class="w-8 h-8 flex items-center text-blumine hover:text-big-stone transition ease-out duration-150"
                  title="Edit"
                >
                  <span class="sr-only">edit</span>
                  <span class="material-icons-outlined">create</span>
                </router-link>
                <button type="button" @click.stop="remove(item.id)" class="w-8 h-8 flex items-center text-blumine hover:text-red-600 transition ease-out duration-150 focus:outline-none" title="Delete">
                  <span class="sr-only">delete</span>
                  <span class="material-icons-outlined">delete</span>
                </button>
              </div>
            </v-table-col>
          </v-table-row>

          <v-table-row v-if="filteredItems.length === 0">
            <v-table-col colspan="3">No Announcements</v-table-col>
          </v-table-row>
        </v-table>
      </div>
    </div>
    <div class="mt-4 text-sm text-gray-500">
      Visible times are in timezone: <span class="font-semibold text-big-stone">{{ date.userTimezone() }}</span>
    </div>
  </layout>
</template>

<script>
import { reactive, computed } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import { highlightInString } from '@/helpers/utils.js'
import date from '@/helpers/date.js'
import Layout from '@/layouts/Default.vue'
import SettingsNav from '@/components/settings/Nav'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import TextInput from '@/components/form/Text.vue'
import VTable from '@/components/Table.vue'
import VTableHeader from '@/components/TableHeader.vue'
import VTableRow from '@/components/TableRow.vue'
import VTableCol from '@/components/TableCol.vue'
import AddLink from '@/components/buttons/AddLink.vue'

export default {
  name: 'settings-announcements-index',

  components: {
    Layout,
    AlertResponseWarning,
    TextInput,
    VTable,
    VTableHeader,
    VTableRow,
    VTableCol,
    SettingsNav,
    AddLink,
  },

  setup() {
    const state = reactive({
      activeOnly: false,
      items: [],
      hasError: false,
      accessError: null,
      isLoading: true,
      search: '',
    })

    const getAnnouncements = async () => {
      state.isLoading = true

      try {
        const { data } = await http.get('announcement')
        state.items = data.data.sort((a,b) => {
          return new Date(b.display_till) - new Date(a.display_till)
        })
      } catch (e) {
        state.hasError = true
        state.accessError = e
      }

      state.isLoading = false
    }

    const remove = async (id) => {
      if (window.confirm(`Do you really want delete this announcement?`)) {
        try {
          await http.delete(`announcement/${id}`)
          state.items = state.items.filter(
            (_i) => _i.id !== id
          )
          notification.success('Success', `Announcement has been deleted`)
        } catch (e) {
          notification.error('Error', 'Something went wrong. Please, try again.')
        }
      }
    }

    const filteredItems = computed(() => {
      const now = date.now().utc()
      return state.items.filter((item) => {
        const visible = !state.activeOnly || date.isBetween(now, date.utc(item.display_since), date.utc(item.display_till))
        return item.message.toLowerCase().includes(state.search.toLowerCase()) && visible
      })
    })

    const formatTime = (time) => {
      return date.logFormat(date.utc(time).unix())
    }

    getAnnouncements()

    return {
      state,
      filteredItems,
      highlightInString,
      remove,
      formatTime,
      date,
    }
  }
}
</script>
